@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  color: #333;
}

h2 {
  font-weight: 600;
  margin-top: 0;
}

.content-column h2 {
  margin-top: 2em;
  padding-bottom: 5px;
  /* border-bottom: 2px solid #eee; */
  margin-bottom: 0.5em;
}

h3 {
  font-weight: 600;
  margin-top: 3rem;
  font-size: 18px;
}

.content-column h3 {
  margin-top: 2em;
  padding-bottom: 5px;
  border-bottom: 2px solid #eee;
  margin-bottom: 0;
}
.content-column h2 + h3 {
  margin-top: 1em;
  padding-bottom: 5px;
  border-bottom: 2px solid #eee;
  margin-bottom: 0;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}
h5.section-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
h5 span {
  font-weight: normal;
  opacity: 0.5;
}

.noresults {
  text-align: center;
  color: #888;
  padding: 3rem 0;
}

@media (min-width: 600px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .desktop-only {
    display: none !important;
  }
}

.lead {
  font-size: 16px;
}

.fulltext {
  line-height: 1.5;
}

.center.aligned {
  text-align: center;
}

.right.aligned {
  text-align: right;
}

.dropdown-icon,
.dropdown-icon path {
  stroke: currentColor;
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
.svg-icon.small {
  width: 18px;
}
.svg-icon,
.svg-icon path {
  fill: currentColor;
}

.material-icons {
  vertical-align: middle;
}
.material-icons.small {
  font-size: 18px;
}

.link {
  color: #00A7E7;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  color: #0075BF;
}

.image.fullwidth {
  margin: 1rem 0;
  display: block;
  width: 100%;
}

/* CONTAINER */

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 12px;
}
.container.large {
  max-width: 1200px;
}

/* .container.fullwidth {
  max-width: none;
} */


/* FOOTER */

.footer {
  padding: 1rem 0;
  margin-top: 1rem;
  color: #0075BF;
  font-size: 12px;
  font-weight: 600;
}


/* BUTTON */

.button {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: black;
  font-weight: 600;
  margin: 0;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  line-height: 40px;
  padding: 0 24px;
  transition: background-color .2s;
  cursor: pointer;
  background: none;
  margin-right: 5px;
}
.button.fullwidth {
  width: 100%;
}

.button:last-child {
  margin-right: 0;
}

.button.solid.white {
  background-color: white;
}

.button:hover {
  background-color: #ebebeb;
}

.button.outlined {
  border: 2px solid #ebebeb;
  line-height: 36px;
}

.button.solid.white {
  background-color: white;
}
.button.solid.white:hover {
  background-color: #ebebeb;
}

.button.solid.blue {
  background-color: #0075BF;
  color: white;
}
.button.solid.blue:hover {
  background-color: #004899;
}

.icon.button {
  padding: 0 11px;
}


/* GRID */

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.grid > .item {
  width: 100%;
  padding: 0 12px;
}
.grid.gallery > .item {
  padding: 12px;
}

.grid > .item > img {
  width: 100%;
}

@media (min-width: 320px) {
  .grid > .item {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .grid {
    margin: 0 -12px;
  }
  .grid > .item {
      width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .grid > .item {
      width: 25%;
  }
}

@media (min-width: 900px) {
  .content-column:not(.fullwidth) {
    width: 75%;
  }
}


/* MAINSUB */

.mainsub {
  margin: 1rem -4px;
  position: relative;
}
.mainsub .item {
  width: 100%;
  float: left;
}
.mainsub:after {
  content: "";
  display: block;
  clear: both;
}
.mainsub .item:before {
  content: "";
  display: block;
}
.mainsub.ratio-4x3 .item:before {
  padding-top: 75%;
}
.mainsub.ratio-16x9 .item:before {
  padding-top: 56.25%;
}
.mainsub .item {
  position: relative;
}

.mainsub.two .item {
  width: 50%;
}
@media screen and (min-width: 600px) {
  .mainsub.one .item {
    width: 50%;
  }
}
.mainsub.three .item {
  width: 33.33333%;
}
.mainsub.three .item:first-child {
  width: 66.66667%;
}
.mainsub.four .item {
  width: 25%;
}
.mainsub.four .item:first-child {
  width: 75%;
}
.mainsub.five .item {
  width: 25%;
}
.mainsub.five .item:first-child {
  width: 50%;
}

.mainsub .item .crop-image {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}

.mainsub .item img {
  display: block;
  width: 100%;
}


.mainsub.rounded .item:first-child .crop-image {
  border-radius: 8px 0 0 8px;
}
.mainsub.rounded.one .item:first-child .crop-image {
  border-radius: 8px;
}
.mainsub.rounded.two .item:last-child .crop-image {
  border-radius: 0 8px 8px 0;
}
.mainsub.rounded.three .item:nth-child(2) .crop-image,
.mainsub.rounded.four .item:nth-child(2) .crop-image,
.mainsub.rounded.five .item:nth-child(3) .crop-image {
  border-radius: 0 8px 0 0;
}
.mainsub.rounded.three .item:nth-child(3) .crop-image,
.mainsub.rounded.four .item:nth-child(4) .crop-image,
.mainsub.rounded.five .item:nth-child(5) .crop-image {
  border-radius: 0 0 8px 0;
}

.mainsub > .actions {
  position: absolute;
  right: 16px;
  bottom: 16px;
}


/* CARD */

.card {
  text-decoration: none;
  color: black;
}
.card .image img,
.card .image .crop-image {
  transition: box-shadow .2s;
}

.card .image {
  position: relative;
}
.card .image .extra {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
.card .image .extra .checkbox {
  margin-bottom: 0;
}

.card .content {
  padding: 8px 0;
}
.card .title {
  font-size: 16px;
  font-weight: 600;
  transition: color .2s;
}
.card .meta {
  color: #808080;
}

.card .content .right {
  float: right;
}

.card .hover-action {
  opacity: 0;
  transition: opacity .2s;
}

.card:hover .hover-action {
  opacity: 1;
}

a.card:hover .image img,
a.card:hover .image .crop-image {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
}
a.card:hover .title {
  color: #004899;
}


/* CROP IMAGE */

.crop-image {
  display: block;
  background-position: center;
  background-size: cover;
}
.crop-image:before {
  content: "";
  display: block;
}
.crop-image.square:before,
.crop-image.circle:before {
  padding-top: 100%;
}
.crop-image.rounded {
  border-radius: 16px;
}
.crop-image.circle {
  border-radius: 50%;
}


/* DROPDOWN */

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.dropdown:first-child {
  margin-left: 0;
}

.dropdown .panel {
  position: absolute;
  top: 100%;
  left: 0;
  width: 240px;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  white-space: nowrap;

  font-size: 14px;
  border-radius: 16px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);

  opacity: 0;
  pointer-events: none;

  transition: opacity .2s;
  z-index: 10;
}

.dropdown.open .panel {
  opacity: 1;
  pointer-events: auto;
}

.dropdown .panel.align-right,
.dropdown .panel.align-top-right,
.dropdown .panel.align-bottom-right {
  left: auto;
  right: 0;
}

.dropdown .label {
  display: block;
  cursor: pointer;
  font-size: 14px;
}

.dropdown .label span {
  color: #666;
}

.dropdown .label .dropdown-icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 3px;
  width: 10px;
  transition: transform .2s;
}
.dropdown .label.outlined .dropdown-icon {
  width: 14px;
}

.dropdown .label.outlined {
  border: 1px solid #ebebeb;
  border-radius: 20px;
  line-height: 38px;
  padding: 0 16px;
  transition: border-color .2s;
}

.dropdown .label.outlined:hover {
  border-color: black;
}

.dropdown.open .label.outlined,
.dropdown.active .label.outlined {
  border-color: black;
  background-color: #fafafa;
}
.dropdown.open .label .dropdown-icon {
  transform: rotate(180deg);
}


.dropdown .label.plain {
  border-radius: 8px;
  line-height: 40px;
  padding: 0 16px;
  font-weight: 600;
  transition: background-color .2s;
}

.dropdown .label.plain:hover {
  background-color: #fafafa;
}

.dropdown.open .label.plain,
.dropdown.active .label.plain {
  background-color: #fafafa;
  color: black;
}


/* LIST */

.list {
  margin: 8px 0;
}

.list > .item {
  display: block;
  padding: 0 16px;
  line-height: 40px;
  color: black;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color .2s;
}
.list > .item.active {
  font-weight: 600;
}
.list > .item:hover {
  background-color: #fafafa;
}

.list > label.item {
  display: flex;
  align-items: center;
}

.list > label.item .checkbox {
  margin-bottom: 0;
  margin-right: 0.5rem;
}


/* TOPBAR */

/* .topbar {
  border-bottom: 1px solid #ebebeb;
} */

.topbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media screen and (max-width: 599px) {
  .topbar .container {
    padding-left: 24px;
  }
}

.topbar .brand {
  position: relative;
  top: -3px;
}
.topbar .brand img {
  width: 150px;
  vertical-align: middle;
}

@media screen and (max-width: 599px) {
  .topbar .brand img {
    width: 100px;
  }
}

.account-button .label.outlined {
  padding-right: 5px;
}
.account-button img {
  width: 30px;
  vertical-align: middle;
  margin-left: 5px;
}


/* FILTERBAR */

.filterbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px;
}

.filterbar .right .dropdown,
.filterbar .right .toggle {
  margin-left: 8px;
}

.filterbar .left {
  flex-grow: 1;
  width: 100%;
  padding-right: 12px;
}
.filterbar .right {
  flex-shrink: 0;
}


/* FILTER PANEL */

.filterpanel {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  max-width: 100%;
  z-index: 10000;
  background-color: white;
  box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  height: -webkit-fill-available;
}

.filterpanel-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.filterpanel-section {
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
}
.filterpanel-actions {
  flex-shrink: 0;
  display: flex;
  justify-content: space-evenly;
}
.filterpanel-button {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-weight: 600;
  margin: 0;
  border: none;
  border-radius: 0;
  font-size: 14px;
  line-height: 40px;
  padding: 0 24px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #333;
  transition: background-color .2s, border-color .2s;
}
.filterpanel-close {
  color: #333;
  background-color: white;
}
.filterpanel-confirm {
  color: white;
  background-color: #333;
}
.filterpanel-label {
  margin-bottom: 10px;
}


/* CHECKLIST */

.checklist {
  max-height: 20vh;
  overflow-y: auto;
  font-size: 14px;
}
.checklist-item {
  padding: 5px 0;
  cursor: pointer;
}
.checklist-item:hover {
  background-color: #fafafa;
}
.checklist-item.selected {
  font-weight: 600;
}


/* TOGGLE */

.toggle {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  height: 40px;
  border-radius: 20px;
  padding: 2px;
  background-color: #ebebeb;
}

.toggle .item {
  position: relative;
  z-index: 1;
  line-height: 35px;
  padding: 0 12px;
  cursor: pointer;
}
.toggle .indicator {
  position: absolute;
  top: 2px;
  bottom: 2px;
  width: 36px;
  min-width: 36px;
  background-color: white;
  border-radius: 18px;

  transition: left .2s, width .2s;
}
.toggle .label {
  font-size: 14px;
  display: inline-block;
}
.toggle i + .label {
  margin-left: 5px;
}


/* TABLE */

.table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.table th,
.table td {
  text-align: left;
  padding: 12px;
}

.table.hdense th,
.table.hdense td {
  padding: 12px 4px;
}

.table th:first-child,
.table td:first-child {
  padding-left: 0;
}

.table th:last-child,
.table td:last-child {
  padding-right: 0;
}

.table thead th {
  border-bottom: 2px solid #ebebeb;
}
.table tbody td {
  border-top: 1px solid #ebebeb;
}
.table tbody tr:first-child td {
  border-top: none;
}

.table tfoot td,
.table tfoot th {
  border-top: 2px solid #ebebeb;
}

.table .narrow.column {
  width: 32px;
}

.table .no-pad-left {
  padding-left: 0;
}

.table .no-pad-right {
  padding-right: 0;
}

.table .hover-action {
  opacity: 0;
  transition: opacity .2s;
}

.table tr:hover .hover-action {
  opacity: 1;
}

.table.details th:first-child,
.table.details td:first-child {
  width: 33.33333%;
}

@media screen and (max-width: 599px) {
  .table.details th:first-child,
  .table.details td:first-child {
    width: 50%;
  }
}

.table.details td:first-child {
  color: #666;
}

.accordion .table.details td:first-child {
  padding-left: 20px;
}


/* LIST VIEW */

@media screen and (max-width: 599px) {
  .listview {
    margin: 0 -12px;
  }
}
.listview-item {
  border-bottom: 1px solid #ebebeb;
  display: flex;
}
.listview-item .image {
  width: 78px;
  padding: 12px;
  flex-shrink: 0;
  /* padding: 12px 12px 12px 18px; */
  position: relative;
}
.listview-item .image .crop-image {
  position: relative;
}
.listview-item .image .extra {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
.listview-item .image .crop-image .extra {
  left: 0;
  bottom: 0;
}
.listview-item .content {
  padding: 12px 12px 12px 0;
  flex-grow: 1;
}
.listview-item .content .right {
  float: right;
}
.listview-item .content .title {
  font-weight: 600;
}
.listview-item .content .meta {
  color: #888;
  font-size: 14px;
}
.listview.border-top {
  border-top: 1px solid #ebebeb;
}



/* TOOLTIPS */

.with-tooltip {
  position: relative;
}
.with-tooltip .tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 10px 12px;
  background-color: black;
  border-radius: 5px;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  pointer-events: none;

  opacity: 0;
  transform: translate(-50%, -10px);

  transition: transform .2s, opacity .2s;
}
.with-tooltip:hover .tooltip {
  opacity: 1;
  transform: translate(-50%, 0);
}
.with-tooltip .tooltip:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  width: 8px;
  height: 8px;
  background-color: black;
  transform: rotate(45deg);
}


/* OBJECT HEADER */

.object-header {
  margin-top: 1rem;
}

.object-header .actions {
  margin-top: 0.5rem;
}

.object-header .right {
  margin-top: 1rem;
}

@media screen and (min-width: 600px) {
  .object-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .object-header .actions {
    margin-top: 1rem;
  }

  .object-header .right {
    margin-top: 0;
  }
}

.object-header h2 {
  margin-bottom: 0;
  font-size: 1.7rem;
  font-weight: 600;
}

.object-header .meta {
  font-size: 16px;
  color: #808080;
}


@media print {
  html {
      font-size: 10pt;
  }

  .container {
      padding: 0 2cm;
  }

  .grid > .item {
      width: 50%;
  }

  .table th,
  .table td {
      padding: 8px;
  }

  .table.hdense th,
  .table.hdense td {
      padding: 8px 4px;
  }

  .table thead {
      background-color: #ebebeb;
  }

  /*.table.details th:first-child,
  .table.details td:first-child {
      width: auto;
  }*/

  .page {
      page-break-after: always;
  }

  @page {
    size: auto;
    margin: 2cm;
  }

  .page {
    padding: 0;
  }
}


/* MODAL */

html.modal-open,
html.modal-open body {
  overflow: hidden;
}

@keyframes modal-dimmer-fade {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes modal-fade {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: none; }
}

.modal-dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
  animation: modal-dimmer-fade .3s forwards;
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}
.modal-container-fullscreen {
  display: block;
}

.modal {
  display: none;
}

.modal-container .modal {
  display: block;
  position: relative;
  background-color: white;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  width: 640px;
  max-width: calc(100% - 40px);
  transform: translateY(20px);
  animation: modal-fade .3s forwards;
}

.modal-container-fullscreen .modal {
  width: 100%;
  height: 100%;
  max-width: none;
}

.modal-content {
  padding: 20px;
  min-height: 64px;
}

.modal-container-fullscreen .modal-content {
  height: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-appearance: none;
  border: none;
  margin: 0;
  background: none;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 0;
  cursor: pointer;
  color: #888;
  transition: color .2s;
  z-index: 100;
}
.modal-close:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 0;
  transform: scale(0.01);
  transition: transform .2s, opacity .2s;
}
.modal-close i {
  position: relative;
  z-index: 1;
}
.modal-close:hover {
  color: black;
}
.modal-close:hover:before {
  opacity: 1;
  transform: none;
}


/* CHECKBOXES */

.checkbox {
  position: relative;
  margin-bottom: 0.5rem;
  height: 18px;
}
.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox label {
  padding-left: 32px;
  display: inline-block;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  vertical-align: top;
}
.checkbox.fitted label {
  padding-left: 18px;
  vertical-align: middle;
}
.checkbox label:before,
.checkbox label:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
}
.checkbox label:before {
  border-radius: 2px;
  border: 2px solid #888;
  background-color: #FFF;
  transition: background-color .2s, border-color .2s;
}
.checkbox :checked + label:before,
.checkbox :indeterminate + label:before {
  border-color: #0075BF;
  background-color: #0075BF;
}
.checkbox label:after {
  background: center / 18px 18px no-repeat url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxOCAxOCIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yLjUgOS4xbDEuMi0xLjJsMy42IDMuNmw3LjItNy4ybDEuMiAxLjJsLTguNCA4LjR6IiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==');
}
.checkbox :indeterminate + label:after {
  background: center / 18px 18px no-repeat url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxOCAxOCIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00IDloMTAiIGZpbGw9Im5vbmUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==');
}


/* AUTH PAGE */

.auth-page {
  position: relative;
  min-height: 100vh;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fafafa;
  background-position: center;
  background-size: cover;
}
.auth-content {
  position: relative;
  background-color: white;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 5px;
  z-index: 10;
  width: 460px;
  max-width: 100%;
}
.auth-content > .brand {
  display: block;
  text-align: center;
}
.auth-content > .brand img {
  width: 150px;
  max-width: 100%;
}
.auth-header {
  margin-bottom: 32px;
}
.auth-header p {
  font-size: 16px;
}


/* FORM */
.form .field {
  margin-bottom: 20px;
}
.form .field > label {
  display: block;
  margin-bottom: 5px;
}
.form .field > input {
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  transition: border-color .2s;
}
.form .field > input:focus {
  border-color: #0075BF;
}
.form .field-hint {
  margin-top: 5px;
}
.form .label-hint {
  float: right;
}
.form .field.error input {
  border-color: #BF1314;
}
.form .field.error .field-hint {
  color: #BF1314;
}


/* ALERT */
.alert {
  padding: 10px 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.alert.danger {
  background-color: #BF131410;
  color: #BF1314;
}


/* PAGINATION */

.pagination {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  display: flex;
}
.pagination li {
  display: block;
  padding: 0 10px 0 0;
}
.pagination li span {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.pagination li span:not(.dots):hover {
  background-color: #eee;
}
.pagination li.current span {
  background-color: #333;
  color: white;
  font-weight: 600;
}

.pagination.align-center {
  justify-content: center;
}
.pagination.align-center li {
  padding: 0 5px;
}
.pagination.align-right {
  justify-content: flex-end;
}
.pagination.align-right li {
  padding: 0 0 0 10px;
}


/* ACCORDION */

.accordion {
  display: block;
  margin-bottom: 20px;
}

.accordion-details {
  height: 0;
  overflow: hidden;
}
.accordion-summary {
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #eee;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
}
.accordion-summary:after {
  content: '';
  display: block;
  position: absolute;
  top: 14px; left: 0;
  width: 14px;
  height: 14px;
  background: center / 14px 14px no-repeat url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00IDJsNiA2bC02IDYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzg4OCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+');
  transition: transform .3s;
}
.accordion-item {
  transition: background-color .2s;
}
.accordion-item.open {
  background-color: rgba(0, 0, 0, 0.025);
}
.accordion-item.open > .accordion-summary:after {
  transform: rotate(90deg);
}

.accordion-details table {
  border-bottom: 1px solid #ccc;
}


/* LOADER */

@keyframes spin {
  from { transform: none; }
  to { transform: rotate(360deg); }
}

.loader {
  position: relative;
  height: 200px;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.loader:before {
  border: 4px solid #eee;
}
.loader:after {
  border: 4px solid transparent;
  border-top-color: #0075BF;
  animation: spin 1s linear infinite;
}


/* TABS */

.tabs {
  border-bottom: 1px solid #eee;
}
.tabs .container {
  display: flex;
  padding: 0;
  margin-bottom: -1px;
}

@media screen and (max-width: 599px) {
  .tabs .container {
    padding-left: 12px;
  }
}

.tabs .tab {
  padding: 12px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #666;
  transition: color .2s, border-bottom-color .2
}
.tabs .tab:hover {
  color: #333;
}
.tabs .tab.active {
  color: #0075BF;
  border-bottom-color: #0075BF;
}


/* SEARCH BAR */

.searchbar {
  border: 1px solid #ebebeb;
  border-radius: 20px;
  line-height: 38px;
  padding: 0 8px 0 8px;
  transition: border-color .2s;
  width: 100%;
}
.searchbar input {
  -webkit-appearance: none;
  border: none;
  background: none;
  border-radius: none;
  padding-left: 10px;
  margin: 0 8px 0 0;
  outline: none;
  border: none;
  width: calc(100% - 35px);
}


/* SLIDESHOW */

.photo-slideshow {
  height: 100%;
}

.photo-slideshow-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}
.photo-slideshow-slide img {
  width: 100%;
}

.photo-slideshow .swiper-button-prev,
.photo-slideshow .swiper-button-next {
  width: 44px;
  height: 44px;
  background-color: #eee;
  border-radius: 50%;
}
.photo-slideshow .swiper-button-prev {
  padding-right: 2px;
}
.photo-slideshow .swiper-button-next {
  padding-left: 2px;
}
.photo-slideshow .swiper-button-prev:after,
.photo-slideshow .swiper-button-next:after {
  font-size: 18px;
  color: #333;
}


/* ACTION BAR */
.actionbar {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 1000;
  background-color: white;
  border-top: 1px solid #eee;
}
.actionbar.visible {
  display: block;
}


.details-table .details-row {
  display: flex;
}
.details-table .details-head {
  padding: 12px 0 12px 20px;
  font-weight: bold;
  border-bottom: 2px solid #eee;
}
.details-table .details-foot {
  padding: 12px 0 12px 20px;
  font-weight: bold;
  border-top: 1px solid #eee;
}
.details-table .details-row div {
  width: 25%;
  padding: 2px;
}
.details-table .details-head div:first-child,
.details-table .details-foot div:first-child {
  position: relative;
  left: -20px;
}

.details-table .accordion {
  margin-bottom: 0;
}
.details-table .accordion {
  margin-bottom: 0;
}

.details-table.details-table-print .details-head,
.details-table.details-table-print .details-row,
.details-table.details-table-print .details-foot {
  padding: 12px 0;
}
.details-table.details-table-print .details-row {
  border-bottom: 1px solid #eee;
}
.details-table.details-table-print .details-foot {
  border-bottom: none;
}
.details-table.details-table-print .details-head div:first-child,
.details-table.details-table-print .details-foot div:first-child {
  left: 0;
}

.details-table.details-table-print table {
  border-bottom: 1px solid #888;
}

.details-table .details-row .w {
  width: 33.33333%;
}
.details-table .details-row .n {
  width: 16.66667%;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 700px) {
  .mobiletables{
    font-size: 11px !important;
  }
  .mobiletables h5{
    font-size: 12px !important;
  } 
}

@media (max-width: 415px) {
  .mobiletables{
    font-size: 10px !important;
  }
}

@media (max-width: 376px) {
  .mobiletables{
    font-size: 9px !important;
  }
}


@media (max-width: 321px) {
  .mobiletables{
    font-size: 8px !important;
  }
}
