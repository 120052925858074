*, :before, :after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
}

body, button, input, select, textarea {
  font-family: 'Avenir Next', sans-serif;
  font-size: 1em;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
